import Head from "next/head"
import { useRouter } from "next/router"
import { FC } from "react"

import { SeoMetaTags } from "src/components/shared/SeoMetaTags"
import { appIcons } from "src/components/shared/appIcons"
import { SeoMetaTagsProps } from "src/components/shared/jsonLd"
import { seoTranslations } from "src/components/shared/seoTranslations"
import { useLocalizedText } from "src/components/state/localization"
import { appConfig, appLocales, appRoutes, canonicalOrigin } from "src/config"

import { SocialMetaTags } from "./SocialMetaTags"

export interface MetaTagsProps {
  title?: string
  keywords?: string
  noIndex?: boolean
  canonicalPagePath?: string
  schemaOrg?: SeoMetaTagsProps["schemaOrg"]
}

export const MetaTags: FC<MetaTagsProps> = ({ title, keywords, noIndex, canonicalPagePath, schemaOrg }) => {
  const router = useRouter()
  const T = useLocalizedText(seoTranslations)

  title = [title, T.title].filter(Boolean).join(" | ")
  canonicalPagePath = canonicalPagePath || router.asPath.split(/[?#]/)[0]!
  keywords = keywords || T.keywords
  appLocales
  const locale = router.locale
  const canonicalLocalePath = locale === appLocales[0] ? "" : "/" + locale
  const canonicalUrl = canonicalOrigin + canonicalLocalePath + canonicalPagePath
  const description = T.description

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <link rel="apple-touch-icon" sizes="180x180" href={appIcons.appleTouchIcon} />
        <link rel="icon" type="image/png" sizes="32x32" href={appIcons.favicon32x32} />
        <link rel="icon" type="image/png" sizes="16x16" href={appIcons.favicon16x16} />
        <link rel="manifest" href={appRoutes.webmanifest} />
        <link rel="mask-icon" href={appIcons.safariPinnedTab} color={appConfig.themeColor} />
        <meta name="msapplication-config" content="/api/browserconfig.xml" />
        <meta name="theme-color" content={appConfig.backgroundColor} />
      </Head>

      <SocialMetaTags title={title} description={description} canonicalUrl={canonicalUrl} locale={locale} />

      <SeoMetaTags
        canonicalPagePath={canonicalPagePath}
        canonicalUrl={canonicalUrl}
        noIndex={noIndex}
        schemaOrg={schemaOrg}
      />
    </>
  )
}
