import { appConfig } from "src/config"

const de = {
  name: appConfig.siteName,
  title: appConfig.siteName + " — Predigten, Gottesdienste, Bibelarbeiten, Lieder",
  description:
    "Predigten, Gottesdienste, Bibelarbeiten, Lieder zum Ansehen, Anhören, Lesen und Herunterladen in verschiedenen Sprachen",
  keywords: [
    "Predigt",
    "Predigten",
    "Bibel",
    "Bibelstudium",
    "Bibelarbeiten",
    "Bibelauslegung",
    "Audiobibel",
    "Hörbibel",
    "Gottesdienste",
    "christliche Vorträge",
    "christliche Bücher",
    "christliche Hörbücher",
    "christliche Lehre",
    "christliche Musik",
    "Bibelkommentare",
    "Evangelisation",
    "christliche Lieder",
    "Andachten",
    "Biographien",
    "christliche Zeitschriftenbeiträge",
    "christliche Poster",
    "christliche Erlebnisberichte",
    "Kurzbotschaften",
    "christliche Musicals",
    "christliche Videos",
    "Seelsorge",
    "christliche Impulse",
    "christliche Jugendtage",
    "christliche Feiern",
    "Missionstage",
    "christliche Interviews",
    "christliche Hörspiele",
    "christliche Dramen",
    "christliche Gedichte",
    "christliche Kurse",
    "christliche Zitate",
    "Videobibel",
    "Gemeinde",
    "Glaube",
    "Gott",
    "Kirche",
  ].join(", "),
}

const en: typeof de = {
  name: appConfig.siteName,
  title: appConfig.siteName + " — Sermons, Church Services, Bible Studies, Songs",
  description:
    "Sermons, church services, bible studies, songs to watch, listen to, read and download in various languages",
  keywords: [
    "sermon",
    "sermons",
    "bible",
    "bible studies",
    "biblical exegesis",
    "biblical interpretation",
    "audio bible",
    "preaching",
    "christian lectures",
    "christian books",
    "christian audio books",
    "christian doctrine",
    "christian teaching",
    "christian music",
    "bible commentary",
    "biblical commentary",
    "evangelism",
    "christian songs",
    "devotions",
    "prayers",
    "christian meditation",
    "biographies",
    "christian newspater contributions",
    "christian posters",
    "christian experience reports",
    "christian short messages",
    "christian musicals",
    "christian videos",
    "pastoring",
    "pastoral care",
    "pastoral counseling",
    "pastoral ministry",
    "christian impulses",
    "christian youth conferences",
    "christian celebrations",
    "christian conferences",
    "christian interviews",
    "christian audio drama",
    "christian radio play",
    "christian poems",
    "christian courses",
    "christian quotes",
    "video bible",
    "congregation",
    "faith",
    "God",
    "church",
  ].join(", "),
}

export const seoTranslations = { de, en }
