const de = {
  fullTextSearch: "Volltextsuche",
  author: "Autor",
  authors: "Autoren",
  allAuthors: "Alle Autoren",
  language: "Sprache",
  languages: "Sprachen",
  allLanguages: "Alle Sprachen",
  category: "Kategorie",
  categories: "Kategorien",
  allCategories: "Alle Kategorien",
  mediaType: "Medientyp",
  mediaTypes: "Medientypen",
  allMediaTypes: "Alle Medientypen",
  sortOrder: "Sortierreihenfolge",
  hitsPerPage: "Treffer pro Seite",
  hitsPerPageOption: (count: number): string => `${count} Treffer pro Seite`,
  sortOrderOptions: {
    null: "Sortiert nach Relevanz",
    relevance: "Sortiert nach Relevanz",
    dateAdded: "Sortiert nach Datum hinzugefügt",
    contentDate: "Sortiert nach Datum des Inhalts",
  },
}

const en: typeof de = {
  fullTextSearch: "Full text search",
  author: "Author",
  authors: "Authors",
  allAuthors: "All authors",
  language: "Language",
  languages: "Languages",
  allLanguages: "All languages",
  category: "Category",
  categories: "Categories",
  allCategories: "All categories",
  mediaType: "Media type",
  mediaTypes: "Media types",
  allMediaTypes: "All media types",
  sortOrder: "Sort order",
  hitsPerPage: "Hits per page",
  hitsPerPageOption: (count) => `${count} hits per page`,
  sortOrderOptions: {
    null: "Ordered by relevance",
    relevance: "Ordered by relevance",
    dateAdded: "Ordered by date added",
    contentDate: "Ordered by content date",
  },
}

export const refinementTranslations = { de, en }
