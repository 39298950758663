import Head from "next/head"
import { useRouter } from "next/router"
import { FC, useEffect } from "react"

import { appLocales, canonicalOrigin } from "src/config"

import { SeoMetaTagsProps, createJsonLdGraph } from "./jsonLd"

export const SeoMetaTags: FC<SeoMetaTagsProps> = (props) => {
  const { canonicalPagePath, canonicalUrl, noIndex } = props

  const index = noIndex ? "noindex" : "index"
  const robots = [index, "follow"].join(", ")

  useEffect(() => {
    if (noIndex) {
      console.debug(`${canonicalUrl} is blocked from indexing.`)
    }
  }, [noIndex, canonicalUrl])

  const router = useRouter()
  const graph = createJsonLdGraph(props, router)

  return (
    <Head>
      <meta name="robots" content={robots} />
      <link rel="canonical" href={canonicalUrl} />

      {appLocales.map((locale) => (
        <link
          key={locale}
          rel="alternate"
          hrefLang={locale}
          href={canonicalOrigin + "/" + locale + canonicalPagePath}
        />
      ))}
      <link rel="alternate" hrefLang="x-default" href={canonicalOrigin + canonicalPagePath} />

      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(graph) }} />
    </Head>
  )
}
