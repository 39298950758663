import { NextRouter } from "next/router"
import {
  BreadcrumbList,
  CreativeWork,
  FAQPage,
  Graph,
  ItemListOrderType,
  ItemPage,
  ListItem,
  Organization,
  SearchResultsPage,
  WebPage,
  WebSite,
} from "schema-dts"

import { type SearchPageProps } from "src/components/pages/search/SearchPage"
import { refinementTranslations } from "src/components/pages/search/refinementTranslations"
import { appIcons } from "src/components/shared/appIcons"
import { routeTranslations } from "src/components/shared/routeTranslations"
import { seoTranslations } from "src/components/shared/seoTranslations"
import {
  formatAuthorUrl,
  formatCategoryUrl,
  formatContentUrl,
  formatLanguageUrl,
  formatMediaTypeUrl,
} from "src/components/utils/links"
import { AppLocale, appConfig, appRoutes, canonicalOrigin } from "src/config"
import type { ClientSideFilterData, ContentData, SearchResults } from "src/types/app"

export function buildJsonLdSchemaId(input: string) {
  return canonicalOrigin + "/#schema-" + input.replace(/\W/g, "-").replace(/--/g, "-")
}

export function toIso8601Duration(duration: string | undefined) {
  if (!duration) {
    return
  }

  const [hours, minutes, seconds] = duration.split(":").map((part) => +part || 0)

  return `PT${hours}H${minutes}M${seconds}S`
}

function createCreativeWork(content: ContentData) {
  // https://schema.org/CreativeWork
  const creativeWork: CreativeWork = {
    "@type": "CreativeWork",
    "@id": buildJsonLdSchemaId("CreativeWork-" + formatContentUrl(content.id)),
    name: content.title,
    author: content.authors.map((author) => author.name).join(", "),
    inLanguage: content.languages.map((language) => language.name).join(", "),
    genre: content.categories.map((category) => category.name).join(", "),
    creditText: content.hints.map((hint) => hint.text).join("\n"),
    dateCreated: content.date,
    isAccessibleForFree: true,
    timeRequired: toIso8601Duration(content.duration),
    // image: https://schema.org/ImageObject,
    // audio: https://schema.org/AudioObject,
    // video: https://schema.org/VideoObject,
  }
  return creativeWork
}

export interface SeoMetaTagsProps {
  canonicalPagePath: string
  canonicalUrl: string
  noIndex?: boolean
  schemaOrg?: {
    faq?: { q: string; a: string }[]
    search?: SearchPageProps
    filters?: ClientSideFilterData
    results?: SearchResults
    content?: ContentData
  }
}

export function createJsonLdGraph(seoProps: SeoMetaTagsProps, router: NextRouter) {
  const { canonicalPagePath, schemaOrg } = seoProps
  const T = jsonLdTranslations[router.locale as AppLocale]
  const baseUrl = canonicalOrigin + "/" + router.locale

  const organizationId = buildJsonLdSchemaId("Organization")
  const organization: Organization = {
    // https://schema.org/Organization
    "@type": "Organization",
    "@id": organizationId,
    name: appConfig.siteName,
    logo: canonicalOrigin + appIcons.appleTouchIcon,
    url: baseUrl + appRoutes.legal,
  }

  // https://schema.org/WebSite
  const webSiteId = buildJsonLdSchemaId("WebSite")
  const webSite: WebSite = {
    "@type": "WebSite",
    "@id": webSiteId,
    name: appConfig.siteName,
    inLanguage: router.locale,
    url: baseUrl,
    potentialAction: {
      // https://schema.org/SearchAction
      // https://developers.google.com/search/docs/advanced/structured-data/sitelinks-searchbox
      "@type": "SearchAction",
      target: {
        // https://schema.org/EntryPoint
        "@type": "EntryPoint",
        urlTemplate: baseUrl + appRoutes.search + "?q={search_term_string}",
      },
      "query-input": "required name=search_term_string",
    } as any,
  }

  // https://schema.org/BreadcrumbList
  // https://developers.google.com/search/docs/advanced/structured-data/breadcrumb
  const breadcrumbLists: BreadcrumbList[] = []

  let webPage: WebPage = {
    // https://schema.org/WebPage
    "@type": "WebPage",
    "@id": buildJsonLdSchemaId("WebPage-" + router.locale + canonicalPagePath),
    name: T.seo.name,
    inLanguage: router.locale,
    url: baseUrl + canonicalPagePath,
    publisher: { "@id": organizationId },
    isPartOf: { "@id": webSiteId },
    breadcrumb: breadcrumbLists,
  }

  if (schemaOrg?.faq) {
    const faqPage: FAQPage = {
      ...webPage,
      // https://schema.org/FAQPage
      // https://developers.google.com/search/docs/advanced/structured-data/faqpage
      "@type": "FAQPage",
      "@id": buildJsonLdSchemaId("FAQPage-" + router.locale + canonicalPagePath),
      mainEntity: schemaOrg.faq.map((item) => {
        return {
          // https://schema.org/Question
          "@type": "Question",
          name: item.q,
          acceptedAnswer: {
            // https://schema.org/Answer
            "@type": "Answer",
            text: item.a,
          },
        }
      }),
    }
    webPage = faqPage
  }

  if (schemaOrg?.results) {
    const order = schemaOrg.filters?.order
    const itemListOrder: ItemListOrderType =
      order === "dateAdded" || order === "contentDate" ? "ItemListOrderDescending" : "ItemListOrderAscending"

    const searchResultsPage: SearchResultsPage = {
      ...webPage,
      // https://schema.org/SearchResultsPage
      "@type": "SearchResultsPage",
      "@id": buildJsonLdSchemaId("SearchResultsPage-" + router.locale + canonicalPagePath),
      mainEntity: [
        {
          // https://schema.org/ItemList
          "@type": "ItemList",
          name: T.routes.search,
          itemListOrder,
          numberOfItems: schemaOrg.results.count,
          itemListElement: schemaOrg.results.hits.map((hit, index) => {
            const listItem: ListItem = {
              // https://schema.org/ListItem
              "@type": "ListItem",
              position: index + 1,
              item: {
                ...createCreativeWork(hit),
                url: baseUrl + formatContentUrl(hit.id),
              },
            }
            return listItem
          }),
        },
      ],
    }
    webPage = searchResultsPage

    if (schemaOrg.search && schemaOrg.filters?.author) {
      const author = schemaOrg.search.authorOptions.find((author) => author.value === schemaOrg.filters!.author)

      if (author) {
        breadcrumbLists.push({
          "@type": "BreadcrumbList",
          name: T.refinements.author,
          itemListElement: [
            {
              "@type": "ListItem",
              item: baseUrl + appRoutes.authors,
              name: T.refinements.authors,
              position: 1,
            },
            {
              "@type": "ListItem",
              name: author.text,
              position: 2,
            },
          ],
        })
      }
    }

    if (schemaOrg.search && schemaOrg.filters?.category) {
      const category = schemaOrg.search.categoryOptions.find(
        (category) => category.value === schemaOrg.filters!.category,
      )

      if (category) {
        breadcrumbLists.push({
          "@type": "BreadcrumbList",
          name: T.refinements.category,
          itemListElement: [
            {
              "@type": "ListItem",
              item: baseUrl + appRoutes.categories,
              name: T.refinements.categories,
              position: 1,
            },
            {
              "@type": "ListItem",
              name: category.text,
              position: 2,
            },
          ],
        })
      }
    }

    if (schemaOrg.search && schemaOrg.filters?.language) {
      const language = schemaOrg.search.languageOptions.find(
        (language) => language.value === schemaOrg.filters!.language,
      )

      if (language) {
        breadcrumbLists.push({
          "@type": "BreadcrumbList",
          name: T.refinements.language,
          itemListElement: [
            {
              "@type": "ListItem",
              item: baseUrl + appRoutes.languages,
              name: T.refinements.languages,
              position: 1,
            },
            {
              "@type": "ListItem",
              name: language.text,
              position: 2,
            },
          ],
        })
      }
    }
  }

  if (schemaOrg?.content) {
    const itemPage: ItemPage = {
      ...webPage,
      "@type": "ItemPage",
      "@id": buildJsonLdSchemaId("ItemPage-" + router.locale + canonicalPagePath),
      mainEntity: createCreativeWork(schemaOrg.content),
    }
    webPage = itemPage

    if (schemaOrg.content.authors.length) {
      schemaOrg.content.authors.map((author) => {
        breadcrumbLists.push({
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              item: baseUrl + appRoutes.authors,
              name: T.refinements.authors,
              position: 1,
            },
            {
              "@type": "ListItem",
              item: baseUrl + formatAuthorUrl(author),
              name: author.name,
              position: 2,
            },
            {
              "@type": "ListItem",
              name: schemaOrg.content!.title,
              position: 3,
            },
          ],
        })
      })
    }

    if (schemaOrg.content.categories.length) {
      schemaOrg.content.categories.map((category) => {
        breadcrumbLists.push({
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              item: baseUrl + appRoutes.categories,
              name: T.refinements.categories,
              position: 1,
            },
            {
              "@type": "ListItem",
              item: baseUrl + formatCategoryUrl(category),
              name: category.name,
              position: 2,
            },
            {
              "@type": "ListItem",
              name: schemaOrg.content!.title,
              position: 3,
            },
          ],
        })
      })
    }

    if (schemaOrg.content.languages.length) {
      schemaOrg.content.languages.map((language) => {
        breadcrumbLists.push({
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              item: baseUrl + appRoutes.languages,
              name: T.refinements.languages,
              position: 1,
            },
            {
              "@type": "ListItem",
              item: baseUrl + formatLanguageUrl(language),
              name: language.name,
              position: 2,
            },
            {
              "@type": "ListItem",
              name: schemaOrg.content!.title,
              position: 3,
            },
          ],
        })
      })
    }

    if (schemaOrg.content.mediaTypes.length) {
      schemaOrg.content.mediaTypes.map((mediaType) => {
        breadcrumbLists.push({
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              item: baseUrl + formatMediaTypeUrl(mediaType),
              name: mediaType.name,
              position: 1,
            },
            {
              "@type": "ListItem",
              name: schemaOrg.content!.title,
              position: 2,
            },
          ],
        })
      })
    }
  }

  if (breadcrumbLists.length) {
    webPage.breadcrumb = breadcrumbLists
  }

  const graph: Graph = {
    "@context": "https://schema.org",
    "@graph": [organization, webSite, webPage],
  }

  return graph
}

const de = {
  seo: seoTranslations.de,
  refinements: refinementTranslations.de,
  routes: routeTranslations.de,
}

const en = {
  seo: seoTranslations.en,
  refinements: refinementTranslations.en,
  routes: routeTranslations.en,
}

const jsonLdTranslations = { de, en }
