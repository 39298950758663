import Head from "next/head"
import { FC } from "react"

import { appIcons } from "src/components/shared/appIcons"
import { canonicalOrigin } from "src/config"

export interface OpenGraphMetaTagsProps {
  title: string
  description: string
  locale: string | undefined
  canonicalUrl: string
}

export const SocialMetaTags: FC<OpenGraphMetaTagsProps> = ({ title, description, locale, canonicalUrl }) => (
  <Head>
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={canonicalOrigin + appIcons.openGraphBanner} />
    <meta property="og:image:width" content="1200" />
    <meta property="og:image:height" content="630" />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={canonicalUrl} />
    {locale && <meta property="og:locale" content={locale} />}
  </Head>
)
